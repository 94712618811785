import { Box, type SxProps } from '@mui/material';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
  fullHeight?: boolean;
  hideWithCss?: boolean;
  sx?: SxProps;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, hideWithCss, fullHeight, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={{
        ...(fullHeight ? { display: 'flex', height: '100%' } : {}),
        display: value !== index && hideWithCss ? 'none' : 'block',
      }}
      {...other}
    >
      {/* biome-ignore lint/complexity/noUselessFragments: <explanation> */}
      {value === index && <>{children}</>}
    </Box>
  );
}

export default TabPanel;
