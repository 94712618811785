import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Avatar, Link, Stack } from '@mui/material';
import type {
  AllowedExtensions,
  EntityType,
  FileWithLoadingState,
  FileWithStatus,
  FileWithSuccessState,
} from 'components/trips/attachments/types';
import { enqueueSnackbar as toast } from 'notistack';
import { type Dispatch, type SetStateAction, useCallback } from 'react';
import {
  type DropEvent,
  ErrorCode,
  type FileRejection,
  useDropzone,
} from 'react-dropzone';
import { uploadAttachment } from 'requests/upload';
import { getMimeTypes } from 'utils/client/formatting';
import T from '../T';

type UploadFilesDropzoneProps = {
  allowedExtensions: AllowedExtensions[];
  multiple?: boolean;
  setFiles: Dispatch<SetStateAction<FileWithStatus[]>>;
  entityId: string | undefined;
  entityType: EntityType;
};

const UploadFilesDropzone = ({
  allowedExtensions,
  multiple,
  setFiles,
  entityId,
  entityType,
}: UploadFilesDropzoneProps) => {
  const onDrop = useCallback(async (droppedFiles: File[]) => {
    if (!entityId) return;
    droppedFiles.forEach(async (file) => {
      try {
        const fileWithStatus: FileWithLoadingState = {
          file,
          status: 'loading',
          name: file.name,
        };

        setFiles((prevFiles) => [...prevFiles, fileWithStatus]);

        const { id, name } = await uploadAttachment({
          file,
          entityId,
          entityType,
        });
        const uploadedFile: FileWithSuccessState = {
          file,
          status: 'success',
          name: name,
          id,
        };

        setFiles((prevFiles) => {
          const fileIndex = prevFiles.findIndex((f) => f.file === file);
          if (fileIndex === -1) return prevFiles;

          return prevFiles.with(fileIndex, uploadedFile);
        });

        toast('File uploaded successfully');
      } catch (error) {
        setFiles((prevFiles) => {
          const fileIndex = prevFiles.findIndex((f) => f.file === file);
          if (fileIndex === -1) return prevFiles;
          return [
            ...prevFiles.slice(0, fileIndex),
            ...prevFiles.slice(fileIndex + 1),
          ];
        });
      }
    });
  }, []);

  const onDropRejected: (
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void = useCallback((fileRejections) => {
    const { filesTooLarge, filesWithInvalidType, filesTooSmall, tooManyFiles } =
      fileRejections.reduce(
        (acc, { errors, file }) => {
          errors.forEach((error) => {
            switch (error.code) {
              case ErrorCode.FileInvalidType:
                acc.filesWithInvalidType.push(file.name);
                break;
              case ErrorCode.FileTooLarge:
                acc.filesTooLarge.push(file.name);
                break;
              case ErrorCode.FileTooSmall:
                acc.filesTooSmall.push(file.name);
                break;
              case ErrorCode.TooManyFiles:
                acc.tooManyFiles.push(file.name);
                break;
            }
          });

          return acc;
        },
        {
          filesTooLarge: [] as string[],
          filesWithInvalidType: [] as string[],
          filesTooSmall: [] as string[],
          tooManyFiles: [] as string[],
        },
      );

    const invalidTypeErrorMessage = filesWithInvalidType.length
      ? `Files with invalid file types: ${filesWithInvalidType.join(', ')}`
      : '';
    const tooLargeErrorMessage = filesTooLarge.length
      ? `Files with size too large: ${filesTooLarge.join(', ')}`
      : '';
    const tooSmallErrorMessage = filesTooSmall.length
      ? `Files with size too small: ${filesTooSmall.join(', ')}`
      : '';
    const tooManyFilesErrorMessage = tooManyFiles.length
      ? 'Too many files. Please upload up to 30 files at once.'
      : '';

    const errorMessage = [
      invalidTypeErrorMessage,
      tooLargeErrorMessage,
      tooSmallErrorMessage,
      tooManyFilesErrorMessage,
    ]
      .filter(Boolean)
      .join('\n');
    toast(errorMessage, { variant: 'error' });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({
    accept: getMimeTypes(allowedExtensions),
    multiple,
    maxFiles: 100,
    maxSize: 100 * 1024 * 1024,
    onDrop,
    onDropRejected,
  });

  const borderColor =
    (isDragAccept && 'accent.purple.dark') ||
    (isDragReject && 'error.light') ||
    'accent.purple.light';

  const bgColor =
    (isDragAccept && 'accent.purple.light') || 'background.default';

  return (
    <Stack
      p={3}
      border={'2px dashed'}
      borderRadius={1}
      borderColor={borderColor}
      bgcolor={bgColor}
      spacing={1}
      alignItems="center"
      {...getRootProps()}
    >
      <Avatar sx={{ bgcolor: 'accent.purple.light' }}>
        <input {...getInputProps()} />
        <UploadFileIcon sx={{ color: 'accent.purple.dark' }} />
      </Avatar>

      {isDragActive ? (
        <T>Drop the files here...</T>
      ) : (
        <T>
          <Link sx={{ ':hover': { cursor: 'pointer' } }}>Click to upload</Link>{' '}
          or drag and drop
        </T>
      )}

      <T variant="body2" color="text.secondary">
        {allowedExtensions.map((ext) => ext.slice(1).toUpperCase()).join(', ')}{' '}
        (max 100MB)
      </T>
    </Stack>
  );
};

export default UploadFilesDropzone;
