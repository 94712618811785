import { getDownloadUrl } from 'requests/download';

export const handleDownload = async ({
  fileName,
  externalFilename,
}: {
  fileName: string;
  externalFilename: string;
}) => {
  if (!externalFilename) return;

  const externalFilenameUrl = await getDownloadUrl(externalFilename);

  const gcsResponse = await fetch(externalFilenameUrl);
  const blob = await gcsResponse.blob();
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(url);
};
