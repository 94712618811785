import { ListItemAvatar, ListItemText, MenuItem } from '@mui/material';
import ListItemWithMenu from 'components/common/lists/ListItemWithMenu';
import { useConfirm } from 'material-ui-confirm';
import {
  getFileIconFromFilename,
  removeExtensionFromFilename,
} from 'utils/client/formatting';
import type { AttachmentResponseDto } from '../../trips/attachments/types';
import { handleDownload } from './utils';

type AttachmentsListItemProps = {
  attachment: AttachmentResponseDto;
  onDelete: (attachmentId: string) => void;
  hasDivider?: boolean;
};

const AttachmentsListItem = ({
  attachment,
  onDelete,
  hasDivider,
}: AttachmentsListItemProps) => {
  const confirm = useConfirm();
  const FileIcon = getFileIconFromFilename(attachment.externalFilename);

  const handleDelete = () => {
    confirm({
      description:
        'Are you sure you want to delete this file? This file will be deleted immediately. You cannot undo this action.',
      title: 'Delete File',
      confirmationText: 'Delete file',
      confirmationButtonProps: {
        color: 'error',
        variant: 'text',
      },
      cancellationButtonProps: {
        color: 'inherit',
        variant: 'contained',
        disableElevation: true,
      },
      dialogProps: {
        maxWidth: 'xs',
        fullWidth: true,
      },
    }).then(() => {
      onDelete(attachment.id);
    });
  };

  return (
    <ListItemWithMenu
      listItemProps={{
        disableRipple: true,
        divider: hasDivider,
      }}
      menuItems={[
        <MenuItem
          key="download"
          onClick={() =>
            handleDownload({
              fileName: attachment.name,
              externalFilename: attachment.externalFilename,
            })
          }
        >
          Download
        </MenuItem>,
        <MenuItem key="delete" onClick={handleDelete}>
          Delete
        </MenuItem>,
      ]}
    >
      <ListItemAvatar sx={{ alignItems: 'baseline' }}>
        <FileIcon color="action" />
      </ListItemAvatar>
      <ListItemText
        primary={removeExtensionFromFilename(attachment.name)}
        secondary={attachment.externalFilename}
      />
    </ListItemWithMenu>
  );
};

export default AttachmentsListItem;
