import { List, ListItem, Tooltip as MuiTooltip } from '@mui/material';

type TooltipProps = {
  title?: string | string[];
  children: React.ReactNode;
  showInMenu?: true; //not used but required by MUI for grid action items
};

const Tooltip = ({ title, children }: TooltipProps) => {
  const itemList = Array.isArray(title) ? title : title ? [title] : [];

  return (
    <MuiTooltip
      title={
        itemList.filter(Boolean).length > 0 && (
          <List>
            {itemList.map((item, index) => (
              <ListItem
                disableGutters
                disablePadding
                sx={{
                  typography: 'caption',
                  mb: index < itemList.length - 1 ? 0.5 : 0,
                }}
                key={item}
              >
                {item}
              </ListItem>
            ))}
          </List>
        )
      }
    >
      <span>{children}</span>
    </MuiTooltip>
  );
};

export default Tooltip;
