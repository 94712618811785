import Add from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import type React from 'react';

import ClientOnlyPortal from './ClientOnlyPortal';

type PageAddButtonProps = {
  title: string;
  disabled?: boolean;
  onClick: () => void;
};
const PageAddButton: React.FC<PageAddButtonProps> = ({
  title,
  disabled = false,
  onClick,
}) => {
  return (
    <ClientOnlyPortal selector="#add-button">
      <Fab
        size="small"
        color="primary"
        disabled={disabled}
        onClick={onClick}
        title={title}
      >
        <Add />
      </Fab>
    </ClientOnlyPortal>
  );
};

export default PageAddButton;
