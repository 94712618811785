import { Avatar, type AvatarProps, alpha } from '@mui/material';
import T from 'components/common/T';

type BookingCommissionSplitsAdvisorAvatarProps = {
  initials?: string;
  color: string;
  sx?: AvatarProps['sx'];
};

const BookingCommissionSplitsAdvisorAvatar = ({
  initials,
  color,
  sx,
}: BookingCommissionSplitsAdvisorAvatarProps) => (
  <Avatar
    variant="circular"
    sx={{ width: 24, height: 24, bgcolor: alpha(color, 0.2), ...sx }}
  >
    <T variant="caption" sx={{ color }}>
      {initials ?? '--'}
    </T>
  </Avatar>
);
export default BookingCommissionSplitsAdvisorAvatar;
