import { Box } from '@mui/material';

const NoResultsOverlay = ({ visible }: { visible: boolean }) => {
  return (
    <Box
      sx={{
        display: visible ? 'flex' : 'none',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      No results found
    </Box>
  );
};

export default NoResultsOverlay;
