import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import AttachmentsList from 'components/common/AttachmentsTab/AttachmentsList';
import FreshBox from 'components/common/FreshBox';
import UploadFilesDialog from 'components/common/UploadFilesDialog/UploadFilesDialog';
import PageAddButton from 'components/layout/PageAddButton';
import {
  type AttachmentResponseDto,
  EntityType,
  type FileWithStatus,
  type FileWithSuccessState,
} from 'components/trips/attachments/types';
import { enqueueSnackbar as toast } from 'notistack';
import { useState } from 'react';
import { deleteAttachment } from 'requests/upload';
import { copySecureUploadUrlToClipboard } from 'utils/client/clipboard';
import AttachmentsPageAddButton from './AttachmentsPageAddButton';

type AttachmentsTabProps = {
  attachments: AttachmentResponseDto[];
  refresh?: () => void;
  entityType: EntityType;
  entityId: string;
  showAddButton?: boolean;
};

export default function AttachmentsTab({
  attachments,
  refresh,
  entityType,
  entityId,
  showAddButton = false,
}: AttachmentsTabProps) {
  const [showAddAttachmentDialog, setShowAddAttachmentDialog] = useState(false);

  const hasAttachments = attachments && attachments.length > 0;

  const handleNewAttachmentClick = () => {
    setShowAddAttachmentDialog(true);
  };

  const handleCopyUrlClick = () => {
    if (entityType !== EntityType.CLIENT) return;

    copySecureUploadUrlToClipboard(entityId);
    toast('File upload URL copied to clipboard');
  };

  const handleCancelAddAttachmentDialog = (files: FileWithStatus[]) => {
    files.forEach(async (file) => {
      if (file.status === 'success') {
        await deleteAttachment((file as FileWithSuccessState).id);
      }
    });

    setShowAddAttachmentDialog(false);
    refresh?.();
  };

  const handleDeleteFile = async (attachmentId: string) => {
    try {
      const deletedAttachmentOrError = await deleteAttachment(attachmentId);
      if ('error' in deletedAttachmentOrError) {
        return;
      }
      toast('Attachment deleted successfully');
      refresh?.();
    } catch (e) {}
  };

  const handleDone = () => {
    setShowAddAttachmentDialog(false);
    refresh?.();
  };

  return (
    <Box>
      {hasAttachments ? (
        <AttachmentsList
          attachments={attachments || []}
          onDelete={handleDeleteFile}
          onAddButtonClick={
            showAddButton ? handleNewAttachmentClick : undefined
          }
        />
      ) : (
        <FreshBox
          visible={!hasAttachments}
          onClick={handleNewAttachmentClick}
          buttonText="New Attachment"
          startIcon={<AddIcon />}
        />
      )}

      {showAddAttachmentDialog && (
        <UploadFilesDialog
          allowedExtensions={[
            '.pdf',
            '.svg',
            '.png',
            '.jpg',
            '.jpeg',
            '.csv',
            '.xls',
            '.xlsx',
            '.doc',
            '.docx',
          ]}
          multiple
          onCancel={handleCancelAddAttachmentDialog}
          onDone={handleDone}
          open={showAddAttachmentDialog}
          entityId={entityId}
          entityType={entityType}
        />
      )}

      {process.env.NEXT_PUBLIC_SECURE_UPLOAD_ENABLED === '1' &&
      entityType === EntityType.CLIENT ? (
        <AttachmentsPageAddButton
          onNewAttachmentClick={handleNewAttachmentClick}
          onCopyUrlClick={handleCopyUrlClick}
        />
      ) : (
        <PageAddButton
          title="Add new attachments"
          onClick={handleNewAttachmentClick}
        />
      )}
    </Box>
  );
}
