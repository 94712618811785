import type {
  EntityType,
  UploadAttachmentResponseDto,
} from 'components/trips/attachments/types';
import { UploadRequestDto, UploadResponseDto } from 'dtos';

export const uploadFile = async (
  file: File,
  options: { isPublic: boolean } = { isPublic: false },
  useSecurePublicUpload?: boolean,
): Promise<UploadResponseDto> => {
  const queryParams = new URLSearchParams(
    UploadRequestDto.parse({
      fileName: file.name,
      mimeType: file.type,
      ...(options.isPublic && { isPublic: 'true' }),
    }),
  );

  const endpoint = useSecurePublicUpload
    ? `/api/public/upload?${queryParams}`
    : `/api/upload?${queryParams}`;

  const signedWriteUrlResponse = await fetch(endpoint);

  if (!signedWriteUrlResponse.ok) {
    throw new Error(
      `Failed to retrieve signed write URL: ${signedWriteUrlResponse.status} ${signedWriteUrlResponse.statusText}`,
    );
  }

  const { writeUrl, newFileName }: UploadResponseDto =
    await signedWriteUrlResponse.json();

  const uploadResponse = await fetch(writeUrl, {
    method: 'PUT',
    headers: [['Content-Type', file.type]],
    body: file,
  });

  if (!uploadResponse.ok) {
    throw new Error(
      `Failed to upload file: ${uploadResponse.status} ${uploadResponse.statusText}`,
    );
  }

  return UploadResponseDto.parse({ writeUrl, newFileName });
};

export const uploadAttachment = async ({
  entityId,
  entityType,
  file,
  isPublic = false,
  useSecurePublicUpload = false,
}: {
  file: File;
  entityId: string;
  entityType: EntityType;
  isPublic?: boolean;
  useSecurePublicUpload?: boolean;
}): Promise<UploadAttachmentResponseDto> => {
  const { newFileName } = await uploadFile(
    file,
    { isPublic },
    useSecurePublicUpload,
  );

  const attachment = {
    entityId,
    entityType,
    externalFilename: newFileName,
    name: file.name,
  };

  const endpoint = useSecurePublicUpload ? '/api/public/upload' : '/api/upload';

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: [['Content-Type', 'application/json']],
    body: JSON.stringify(attachment),
  });

  return response.json();
};

export const updateAttachmentName = async ({
  attachmentId,
  name,
  useSecurePublicUpload,
}: {
  attachmentId: string;
  name: string;
  useSecurePublicUpload?: boolean;
}): Promise<UploadAttachmentResponseDto> => {
  const endpoint = useSecurePublicUpload
    ? `/api/public/upload?id=${attachmentId}`
    : `/api/upload?id=${attachmentId}`;

  const updatedAttachmentResponse = await fetch(endpoint, {
    method: 'PUT',
    headers: [['Content-Type', 'application/json']],
    body: JSON.stringify({ name }),
  });

  const updatedAttachment = await updatedAttachmentResponse.json();
  return updatedAttachment;
};

export const deleteAttachment = async (
  attachmentId: string,
  useSecurePublicUpload?: boolean,
): Promise<UploadAttachmentResponseDto> => {
  const endpoint = useSecurePublicUpload
    ? `/api/public/upload?id=${attachmentId}`
    : `/api/upload?id=${attachmentId}`;

  const deletedAttachmentResponse = await fetch(endpoint, { method: 'DELETE' });

  const deletedAttachment = await deletedAttachmentResponse.json();
  return deletedAttachment;
};
