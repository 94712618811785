import { MoreVert } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  type SxProps,
} from '@mui/material';
import MenuItemTooltip from 'components/common/Tooltip';
import type React from 'react';
import { useState } from 'react';

export type ActionsMenuButtonAction = {
  id: string;
  label: string;
  onClick: () => void;
  tooltip?: string | string[];
  disabled?: boolean;
  divider?: boolean;
  type?: 'action';
};

export type ActionsMenuButtonActionDivider = {
  type: 'divider';
};

type ActionsMenuButtonProps = {
  actions: (ActionsMenuButtonAction | ActionsMenuButtonActionDivider)[];
  disabled?: boolean;
  sx?: SxProps;
};

export default function ActionsMenuButton({
  disabled,
  actions,
  sx,
}: ActionsMenuButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        disabled={disabled}
        edge="end"
        onClick={handleMenuOpen}
        sx={sx}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
      >
        {actions.map((item, index) => (
          <>
            {item.type === 'divider' ? (
              <Divider
                key={`divider-${
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  index
                }`}
              />
            ) : (
              <MenuItemTooltip key={item.id} title={item.tooltip}>
                <MenuItem
                  key={item.id}
                  onClick={() => {
                    item.onClick();
                  }}
                  disabled={item.disabled}
                >
                  {item.label}
                </MenuItem>
              </MenuItemTooltip>
            )}
          </>
        ))}
      </Menu>
    </>
  );
}
