import { Box, Button } from '@mui/material';
import type React from 'react';

type FreshBoxProps = {
  visible: boolean;
  buttonText?: string;
  labelText?: string;
  onClick: () => void;
  startIcon?: React.ReactNode;
};

export default function FreshBox({
  visible,
  buttonText,
  labelText,
  onClick,
  startIcon,
}: FreshBoxProps): JSX.Element | null {
  return visible ? (
    <Box
      sx={{
        display: 'flex',
        minHeight: '300px',
        backgroundColor: 'background.secondary',
      }}
    >
      <Box sx={{ m: 'auto' }}>
        {buttonText && (
          <Button
            type="button"
            onClick={onClick}
            variant="contained"
            size="large"
            startIcon={startIcon}
          >
            {buttonText}
          </Button>
        )}
        {labelText && <Box sx={{ mt: 2 }}>{labelText}</Box>}
      </Box>
    </Box>
  ) : null;
}
