import AddIcon from '@mui/icons-material/Add';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import ClientOnlyPortal from 'components/layout/ClientOnlyPortal';
import * as React from 'react';

type AttachmentsPageAddButtonProps = {
  onNewAttachmentClick: () => void;
  onCopyUrlClick: () => void;
};

const AttachmentsPageAddButton = ({
  onCopyUrlClick,
  onNewAttachmentClick,
}: AttachmentsPageAddButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <ClientOnlyPortal selector="#add-button">
      <SpeedDial
        ariaLabel="SpeedDial Client Attachments"
        icon={<SpeedDialIcon icon={<AddIcon />} />}
        direction="up"
        FabProps={{
          size: 'small',
        }}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        sx={{
          '& .MuiSpeedDial-actions': {
            height: open ? 'inherit' : 0,
          },
        }}
      >
        <SpeedDialAction
          icon={<AddIcon />}
          tooltipTitle="Add new Attachments"
          onClick={() => {
            onNewAttachmentClick();
            handleClose();
          }}
        />
        <SpeedDialAction
          icon={<CopyAllIcon />}
          tooltipTitle="Copy File Upload URL"
          onClick={() => {
            onCopyUrlClick();
            handleClose();
          }}
        />
      </SpeedDial>
    </ClientOnlyPortal>
  );
};

export default AttachmentsPageAddButton;
