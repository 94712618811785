import { Stack } from '@mui/material';
import type { ReactNode } from 'react';

type UploadFilesFileListProps = {
  children: ReactNode;
};

const UploadFilesFileList = ({ children }: UploadFilesFileListProps) => {
  return <Stack spacing={2}>{children}</Stack>;
};

export default UploadFilesFileList;
