import {
  type TripCancelRequestDto,
  type TripCreateRequestDto,
  type TripPaginatedResponseDto,
  type TripRequestDto,
  type TripResponseDto,
  TripSplitType,
  type TripSplitUpdateRequestDto,
  type TripUpdateRequestDto,
} from 'dtos';
import type {
  GroupCreatePnrResponse,
  TripMergeToPnrDto,
} from 'dtos/mergeToPnr.dtos';
import type {
  TripMarkupWithdrawalCreateRequestDto,
  TripMarkupWithdrawalUpdateRequestDto,
} from 'dtos/tripMarkupWithdrawal.dtos';
import type { CreatePnrResponse } from 'services/mergeToPnr/mergeToPnr.service';
import ky from 'utils/client/ky';

export const getTrips = async ({
  query,
  pageSize = 100,
}: {
  query: string;
  pageSize: number;
}) =>
  ky
    .get(`/api/trips?query=${query}&pageSize=${pageSize}`)
    .json<TripPaginatedResponseDto>()
    .then((response) => response.data);

export const createTrip = async (
  trip: TripCreateRequestDto,
): Promise<TripResponseDto> =>
  ky.post('/api/trips', { json: trip }).json<TripResponseDto>();

export const updateTrip = async (
  trip: TripUpdateRequestDto,
  id: string,
  commissionSplits?: TripSplitUpdateRequestDto,
): Promise<TripResponseDto> => {
  const updatedTrip = await ky
    .put(`/api/trips/${id}`, { json: trip })
    .json<TripResponseDto>();

  if (commissionSplits)
    await updateTripSplits(id, commissionSplits, TripSplitType.COMMISSION);

  return updatedTrip;
};

const updateTripSplits = async (
  tripId: string,
  commissionSplits: TripSplitUpdateRequestDto,
  type: TripSplitType,
): Promise<TripResponseDto> =>
  ky
    .put(`/api/trips/${tripId}/splits/${type}`, { json: commissionSplits })
    .json<TripResponseDto>();

export const lockTrip = async (id: string) =>
  ky.post(`/api/trips/${id}/lock`).json();

export const unlockTrip = async (id: string) =>
  ky.delete(`/api/trips/${id}/lock`).json();

export const cancelTrip = async (id: string) =>
  ky.post(`/api/trips/${id}/cancel`).json();

export const deleteTrip = async (id: string): Promise<TripResponseDto> =>
  ky.delete(`/api/trips/${id}`).json<TripResponseDto>();

export const cancelTripWithRefunds = async ({
  tripId,
  tripItemRefunds,
}: TripRequestDto & { tripItemRefunds: TripCancelRequestDto }) =>
  ky
    .post(`/api/trips/${tripId}/cancel-with-refund`, { json: tripItemRefunds })
    .json();

export const updateTripStage = async ({
  tripId,
  stageId,
}: { tripId: string; stageId: string }) =>
  ky
    .put(`/api/trips/${tripId}/stage`, { json: { leadStageId: stageId } })
    .json();

export const createTripPnr = async ({
  tripId,
  pnr,
}: {
  tripId: string;
  pnr: TripMergeToPnrDto;
}) =>
  ky
    .post(`/api/trips/${tripId}/merge-to-pnr`, { json: pnr })
    .json<CreatePnrResponse>();

export const updateTripPnr = async ({
  tripId,
  pnr,
}: {
  tripId: string;
  pnr: TripMergeToPnrDto;
}) =>
  ky
    .put(`/api/trips/${tripId}/merge-to-pnr`, { json: pnr })
    .json<CreatePnrResponse>();

export const createTripPnrV2 = async ({
  tripId,
  pnr,
}: {
  tripId: string;
  pnr: TripMergeToPnrDto;
}) =>
  ky
    .post(`/api/trips/${tripId}/merge-to-pnr-v2`, { json: pnr })
    .json<CreatePnrResponse>();

export const updateTripPnrV2 = async ({
  tripId,
  pnr,
}: {
  tripId: string;
  pnr: TripMergeToPnrDto;
}) =>
  ky
    .put(`/api/trips/${tripId}/merge-to-pnr-v2`, { json: pnr })
    .json<CreatePnrResponse>();

export const createMergeToPnrFromGroup = async ({
  groupId,
  pnrDto,
}: {
  groupId: string;
  pnrDto: TripMergeToPnrDto;
}) =>
  ky
    .post(`/api/groups/${groupId}/merge-to-pnr-group`, { json: pnrDto })
    .json<GroupCreatePnrResponse>();

export const createTripWithdrawal = async ({
  tripId,
  amount,
  fromClientAmount,
  toSupplierAmount,
}: {
  tripId: string;
  amount: number;
  fromClientAmount: number;
  toSupplierAmount: number;
}) => {
  const body: TripMarkupWithdrawalCreateRequestDto = {
    amount,
    fromClientAmount,
    toSupplierAmount,
  };

  return ky
    .post(`/api/trips/${tripId}/markup-withdrawals`, { json: body })
    .json();
};

export const updateTripWithdrawal = async ({
  tripId,
  withdrawalId,
  amount,
  fromClientAmount,
  toSupplierAmount,
}: {
  tripId: string;
  withdrawalId: string;
  amount: number;
  fromClientAmount: number;
  toSupplierAmount: number;
}) => {
  const body: TripMarkupWithdrawalUpdateRequestDto = {
    amount,
    fromClientAmount,
    toSupplierAmount,
  };

  return ky
    .put(`/api/trips/${tripId}/markup-withdrawals/${withdrawalId}`, {
      json: body,
    })
    .json();
};

export const deleteTripWithdrawal = async ({
  tripId,
  withdrawalId,
}: {
  tripId: string;
  withdrawalId: string;
}) =>
  ky.delete(`/api/trips/${tripId}/markup-withdrawals/${withdrawalId}`).json();
