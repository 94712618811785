import AddIcon from '@mui/icons-material/Add';
import { Button, Stack } from '@mui/material';
import type { AttachmentResponseDto } from '../../trips/attachments/types';
import AttachmentsListItem from './AttachmentsListItem';

type AttachmentsListProps = {
  attachments: AttachmentResponseDto[];
  onDelete: (attachmentId: string) => void;
  onAddButtonClick?: () => void;
};

const AttachmentsList = ({
  attachments = [],
  onDelete,
  onAddButtonClick,
}: AttachmentsListProps) => {
  return (
    <Stack spacing={1}>
      <Stack pr={2}>
        {attachments.map((attachment, index) => (
          <AttachmentsListItem
            key={attachment.id}
            attachment={attachment}
            onDelete={onDelete}
            hasDivider={index < attachments.length - 1}
          />
        ))}
      </Stack>
      {onAddButtonClick && (
        <Button
          fullWidth
          variant="contained"
          color="inherit"
          disableElevation
          startIcon={<AddIcon />}
          onClick={() => onAddButtonClick()}
        >
          New Attachment
        </Button>
      )}
    </Stack>
  );
};

export default AttachmentsList;
