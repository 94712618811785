import type { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import type { SignatureMarketingCodeCategory } from 'data/signatureMarketingCodes';
import {
  type ClientFlagType,
  ContextCookieDto,
  type ProfileResponseDto,
} from 'dtos';
import type { Dispatch } from 'react';

export type ListItem<T = string> = {
  id: T;
  name: string;
};

export type ListItemWithIcon<T = string> = {
  id: T;
  name: string;
  icon?: React.ReactNode;
};

export interface SearchableListItem<T = string> extends ListItem<T> {
  id: T;
  name: string;
  searchableName: string;
}

export type CreatableListItem = {
  id?: string;
  name?: string;
  inputValue: string;
};

export type HierarchicalAgency = {
  id: string;
  name: string;
  singleUserAgency: boolean;
  users: ProfileResponseDto[];
  color: string;
  children: HierarchicalAgency[];
  depth: number;
};

export type Context = {
  agencyId: string;
  userId?: string;
  token?: string;
};

export interface AppBarProps extends MuiAppBarProps {
  open: boolean;
  setOpen: Dispatch<boolean>;
}

export enum DashboardPeriod {
  yearToDate = 'ytd',
  threeMonths = '3m',
  oneYear = '1y',
  custom = 'custom',
}

export enum DashboardView {
  booking = 'booking',
  travel = 'travel',
}

export enum BookingTab {
  BOOKING = 0,
  COMMISSION = 1,
  SABRE = 2,
  ADVISORS_AND_SPLITS = 3,
  ATTACHMENTS = 4,
  ACTIVITY = 5,
}

export enum SupplierPaymentAdjustmentTab {
  Adjustments = 0,
  Activity = 1,
}

export enum ClientVirtuosoMarketingSection {
  AGENCY_MARKETING = 'Agency Marketing',
  MARKETING = 'Virtuoso Marketing',
  TOTAL_TRIP_BUDGET = 'Budget',
  CLIENT_TYPE = 'Client Type',
  DESTINATIONS = 'Destinations',
  HOME_LIFE = 'Home Life',
  INTERESTS = 'Interests',
  TRIP_DURATION = 'Trip Duration',
  TRIP_FREQUENCY = 'Trip Frequency',
}

export enum ClientTravelLeadersMarketingSection {
  MARKETING = 'Marketing',
}

export type FlagModel = {
  header: string;
  type: ClientFlagType;
  description: string;
  checked: boolean;
  disabled: boolean;
  section:
    | ClientVirtuosoMarketingSection
    | ClientTravelLeadersMarketingSection
    | SignatureMarketingCodeCategory;
};
