import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import type {
  AllowedExtensions,
  EntityType,
  FileWithStatus,
  FileWithSuccessState,
  UploadAttachmentResponseDto,
} from 'components/trips/attachments/types';
import { enqueueSnackbar as toast } from 'notistack';
import { useState } from 'react';
import { deleteAttachment, updateAttachmentName } from 'requests/upload';
import UploadFilesDropzone from './UploadFilesDropzone';
import UploadFilesFileItem from './UploadFilesFileItem';
import UploadFilesFileList from './UploadFilesFileList';

type UploadFilesDialogProps = {
  open: boolean;
  onCancel: (files: FileWithStatus[]) => void;
  onDone: (files: FileWithStatus[]) => void;
  allowedExtensions: AllowedExtensions[];
  title?: string;
  multiple?: boolean;
  entityId: string | undefined;
  entityType: EntityType;
};

const UploadFilesDialog = ({
  onCancel,
  open,
  onDone,
  allowedExtensions,
  title = 'Upload Files',
  multiple = false,
  entityId,
  entityType,
}: UploadFilesDialogProps) => {
  const [files, setFiles] = useState<FileWithStatus[]>([]);

  const handleFileDelete = async (fileId: string) => {
    try {
      const deletedFile = await deleteAttachment(fileId);

      toast('File deleted successfully');

      setFiles((prevFiles) =>
        prevFiles.filter(
          (f) =>
            (f as FileWithSuccessState).id !==
            (deletedFile as UploadAttachmentResponseDto).id,
        ),
      );
    } catch (e) {}
  };

  const handleFileNameUpdate = async (fileId: string, name: string) => {
    if (name === '') return;
    await updateAttachmentName({ attachmentId: fileId, name });
  };

  const isUploadButtonDisabled = files.some((f) => f.status === 'loading');

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ padding: 24 }} dividers>
        <Stack spacing={2}>
          <UploadFilesDropzone
            allowedExtensions={allowedExtensions}
            multiple={multiple}
            setFiles={setFiles}
            entityId={entityId}
            entityType={entityType}
          />
          {files.length > 0 && (
            <UploadFilesFileList>
              {files.map((file) => (
                <UploadFilesFileItem
                  key={`${file.file.name}-${file.file.type}-${file.file.size}`}
                  file={file}
                  onUpdateName={handleFileNameUpdate}
                  onDelete={handleFileDelete}
                />
              ))}
            </UploadFilesFileList>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel(files)}>Cancel</Button>
        <Button
          onClick={() => onDone(files)}
          disabled={isUploadButtonDisabled}
          color="primary"
          variant="contained"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFilesDialog;
