import { PaymentMethod } from 'dtos';

import Decimal from 'decimal.js';
import { formatPercentage, roundToTwoDecimals } from './formatting';

const CREDIT_CARD_MARKUP_PCT = 3.2;
const ACH_MARKUP_IN_DOLLARS = 10;

const sumArray = <T>(arr: T[], key: keyof T): number => {
  return arr
    .reduce((acc, curr) => acc.plus(Number(curr[key])), new Decimal(0))
    .toNumber();
};

const getMarkup = (amount: number, markupPercentage: number): number => {
  return roundToTwoDecimals(amount * (markupPercentage / 100));
};

const getMarkupPercentage = (amount: number, profit: number): number => {
  return formatPercentage(amount ? (profit / amount) * 100 : 0);
};

const getCommission = (amount: number, percentage: number): number => {
  return roundToTwoDecimals(amount * (percentage / 100));
};

const getCommissionPercentage = (
  amount: number,
  commissionValue: number,
): number => {
  return formatPercentage(amount ? (commissionValue / amount) * 100 : 0);
};

const getClientInvoiceTotal = (
  amount: number,
  paymentMethod: PaymentMethod,
): number => {
  if (!amount) return 0;

  if (paymentMethod === PaymentMethod.CREDIT_CARD)
    return roundToTwoDecimals(
      amount + getMarkup(amount, CREDIT_CARD_MARKUP_PCT),
    );
  else if (paymentMethod === PaymentMethod.ACH)
    return amount + ACH_MARKUP_IN_DOLLARS;
  else return amount;
};

const restrictToRange = ({
  value,
  min = 0,
  max = 100,
  decimals = null,
}: {
  value: number;
  min?: number;
  max?: number;
  decimals?: number | null;
}): number => {
  let result = value;
  let fractionDigits = 0;
  if (decimals) {
    if (decimals >= 0 && decimals <= 20) fractionDigits = decimals;
    else {
      if (decimals < 0) fractionDigits = 0;
      else fractionDigits = 20;
    }
  }

  if (result < min || isNaN(result)) result = min;
  if (result > max) result = max;

  return decimals
    ? restrictDecimals({ value: result, decimals: fractionDigits })
    : result;
};

const restrictDecimals = ({
  value,
  decimals = 2,
}: {
  value: number;
  decimals?: number;
}): number => {
  if (!value) return 0;

  let fractionDigits;

  if (decimals >= 0 && decimals <= 20) fractionDigits = decimals;
  else {
    if (decimals < 0) fractionDigits = 0;
    else fractionDigits = 20;
  }

  return value % 1 === 0
    ? Number(value.toFixed(0))
    : Number(value.toFixed(fractionDigits));
};

const calculateReducedCommission = ({
  originalCommission,
  reducedPercent,
}: { reducedPercent: Decimal | number; originalCommission: Decimal | number }):
  | number
  | undefined => {
  if (!(reducedPercent instanceof Decimal)) {
    reducedPercent = new Decimal(reducedPercent);
  }
  if (!(originalCommission instanceof Decimal)) {
    originalCommission = new Decimal(originalCommission);
  }

  return originalCommission
    ?.minus(originalCommission.times(reducedPercent.dividedBy(100)))
    .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
    .toNumber();
};

export {
  sumArray,
  getClientInvoiceTotal,
  getCommission,
  getCommissionPercentage,
  getMarkup,
  getMarkupPercentage,
  restrictToRange,
  restrictDecimals,
  calculateReducedCommission,
};
