import { DownloadRequestDto } from 'components/trips/attachments/types';

export const getDownloadUrl = async (
  externalFilename: string,
): Promise<string> => {
  const queryParams = new URLSearchParams(
    DownloadRequestDto.parse({
      externalFilename,
    }),
  );

  const response = await fetch(`/api/download?${queryParams}`);
  const { signedUrl } = await response.json();
  return signedUrl;
};
