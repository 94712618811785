import { Box, type SxProps, type Theme } from '@mui/material';
import type { GridSelectionModel, MuiEvent } from '@mui/x-data-grid';
import {
  DataGridPro as DataGrid,
  type GridColumns,
  type GridInitialState,
  type GridRowParams,
  type GridSlotsComponent,
  type GridValidRowModel,
} from '@mui/x-data-grid-pro';
import type { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import GridNoResultsOverlay from 'components/common/grids/NoResultsOverlay';
import * as React from 'react';
import type { PaginatedDataMeta, SortModel } from 'types/paginatedData';
import type { TypedGridColDef } from 'types/typedGridColDef';

export type GridProps<
  T extends GridValidRowModel,
  CustomCols extends string = '',
> = {
  data?: T[];
  columns: TypedGridColDef<T, CustomCols>[];
  initialState?: GridInitialState;
  meta?: PaginatedDataMeta;
  page: number;
  pageSize: number;
  sortModel: SortModel;
  setSortModel: (sortModel: SortModel) => Promise<boolean>;
  setPage: (page: number) => Promise<boolean>;
  setPageSize: (pageSize: number) => Promise<boolean>;
  isLoading: boolean;
  error?: Error;
  sx?: SxProps<Theme>;
  rowHeight?: number;
  getRowId?: (row: T) => string;
  getRowClassName?: (params: GridRowParams) => string;
  onRowClick?: (
    row: T,
    event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>,
  ) => void;
  components?: Partial<GridSlotsComponent>;
  refresh?: () => void;
  getDetailPanelContent?: DataGridProProps<T>['getDetailPanelContent'];
  getDetailPanelHeight?: DataGridProProps<T>['getDetailPanelHeight'];
  showPagination?: boolean;
  checkboxSelection?: boolean;
  disableRowSelectionOnClick?: boolean;
  isRowSelectable?: ((params: GridRowParams<T>) => boolean) | undefined;
  selectionModel?: GridSelectionModel | undefined;
  onSelectionModelChange?:
    | ((selectionModel: GridSelectionModel) => void)
    | undefined;
  autoHeight?: boolean;
  hideFooter?: boolean;
  headerHeight?: number;
};

export default function Grid<
  T extends GridValidRowModel,
  CustomCols extends string = '',
>({
  data,
  columns,
  initialState,
  meta,
  page,
  pageSize = 50,
  sortModel,
  setSortModel,
  setPage,
  setPageSize,
  isLoading,
  error,
  rowHeight = 40,
  headerHeight,
  getRowId,
  onRowClick,
  sx = {},
  components = {},
  getRowClassName,
  getDetailPanelContent,
  getDetailPanelHeight = (params) => 'auto',
  showPagination = true,
  checkboxSelection,
  disableRowSelectionOnClick = true,
  isRowSelectable,
  selectionModel,
  onSelectionModelChange,
  autoHeight = false,
  hideFooter = false,
}: GridProps<T, CustomCols>) {
  const [rowCount, setRowCount] = React.useState(meta?.totalRowCount || 0);
  React.useEffect(() => {
    setRowCount((prevRowCount: number) => {
      return meta?.totalRowCount !== undefined
        ? meta.totalRowCount
        : prevRowCount;
    });
  }, [meta?.totalRowCount, setRowCount]);
  if (error) {
    console.error(error);
    return <Box>Uh-oh, an error occurred</Box>;
  }
  return (
    <Box style={{ width: '100%' }}>
      <DataGrid
        {...(rowHeight ? { getRowHeight: () => rowHeight } : {})}
        {...(headerHeight ? { headerHeight } : {})}
        sx={{
          ...(onRowClick
            ? {}
            : {
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: 'transparent',
                  cursor: 'default',
                },
              }),
          ...sx,
        }}
        onRowClick={(params: GridRowParams, event) => {
          if (onRowClick) {
            const row = data?.find(
              (item) => (getRowId || (({ id }) => id))(item) === params.id,
            );
            if (row) {
              onRowClick(row, event);
            }
          }
        }}
        autoHeight={autoHeight}
        getRowId={getRowId || ((r) => r.id)}
        isRowSelectable={isRowSelectable}
        disableColumnMenu
        disableSelectionOnClick={disableRowSelectionOnClick}
        rows={data || []}
        rowCount={rowCount}
        loading={isLoading}
        rowsPerPageOptions={[10, 20, 50, 100, 200]}
        pagination={showPagination}
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        sortingOrder={['asc', 'desc']}
        sortModel={[{ field: sortModel.sort, sort: sortModel.sortDirection }]}
        onPageChange={(newPage) => {
          setPage(newPage).catch(null);
        }}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize).catch(null);
        }}
        onSortModelChange={(sortModel) => {
          if (sortModel.length) {
            setSortModel({
              sort: sortModel[0].field,
              sortDirection: sortModel[0].sort || 'asc',
            }).catch(null);
          }
        }}
        columns={columns as GridColumns<T>}
        checkboxSelection={checkboxSelection}
        initialState={initialState}
        disableColumnFilter
        disableColumnSelector
        components={{
          ...components,
          NoRowsOverlay: () => <GridNoResultsOverlay visible={!isLoading} />,
          NoResultsOverlay: () => <GridNoResultsOverlay visible={!isLoading} />,
        }}
        getRowClassName={getRowClassName}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectionModel}
        hideFooter={hideFooter}
      />
    </Box>
  );
}
