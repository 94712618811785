import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  ListItem,
  ListItemButton,
  type ListItemButtonProps,
  Menu,
} from '@mui/material';
import type React from 'react';
import { useRef, useState } from 'react';

export type ListItemWithMenuProps = {
  menuItems: React.ReactElement[];
  listItemProps?: Partial<ListItemButtonProps>;
  children: React.ReactNode;
  disabled?: boolean;
};
export default function ListItemWithMenu({
  menuItems,
  children,
  listItemProps = {},
  disabled = false,
}: ListItemWithMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const ref = useRef(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <>
          <IconButton disabled={disabled} edge="end" onClick={handleMenuOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            onClick={handleMenuClose}
          >
            {menuItems}
          </Menu>
        </>
      }
    >
      <ListItemButton ref={ref} disabled={disabled} {...listItemProps}>
        <ListItem disablePadding disableGutters>
          {children}
        </ListItem>
      </ListItemButton>
    </ListItem>
  );
}
