import {
  LinearProgress,
  ListItemText,
  Stack,
  type SxProps,
} from '@mui/material';
import { useUserInfoContext } from 'context/user-info.context';
import { getCurrencyByCode } from 'data/currencies';
import { formatMoneyStr } from 'utils/client/formatting';

type MoneyProgressProps = {
  label?: string;
  currentValue: number;
  totalValue: number;
  currency: string;
  progressBarColor?: 'success' | 'primary';
  sx?: SxProps;
  homeValue?: number;
  totalHomeValue?: number;
};

const formatMoneyWithCurrency = (value: number, symbol: string) =>
  formatMoneyStr(
    value,
    `${getCurrencyByCode(symbol).symbol}0`,
    getCurrencyByCode(symbol).symbol,
  );

export default function MoneyProgress({
  label,
  currentValue,
  totalValue,
  currency,
  progressBarColor = 'primary',
  sx,
  homeValue,
  totalHomeValue,
}: MoneyProgressProps) {
  const userInfo = useUserInfoContext();

  const homeCurrency = userInfo?.homeCurrency?.code;

  if (!homeCurrency) return null;

  const bookingCurrency = getCurrencyByCode(currency);
  const homeCurrencyObj = getCurrencyByCode(homeCurrency);

  return (
    <Stack sx={sx}>
      <Stack direction="row" justifyContent="space-between">
        <ListItemText
          primary={label || ''}
          primaryTypographyProps={{ color: 'text.primary' }}
          secondary={`${
            formatMoneyWithCurrency(currentValue, currency) || 0
          } / ${formatMoneyWithCurrency(totalValue, currency)} ${bookingCurrency.code}`}
          secondaryTypographyProps={{ typography: 'body1', fontWeight: 'bold' }}
        />
        {homeValue !== undefined && totalHomeValue !== undefined && (
          <ListItemText
            sx={{ textAlign: 'right' }}
            primaryTypographyProps={{
              color: 'text.secondary',
            }}
            secondary={`${
              formatMoneyWithCurrency(homeValue, homeCurrency) || 0
            } / ${formatMoneyWithCurrency(totalHomeValue, homeCurrency)} ${homeCurrencyObj.code}`}
            secondaryTypographyProps={{
              typography: 'body1',
              fontWeight: 'bold',
            }}
          />
        )}
      </Stack>
      <LinearProgress
        variant="determinate"
        value={Math.min(
          currentValue ? (currentValue * 100) / (totalValue || 1) : 0,
          100,
        )}
        sx={{ width: '100%' }}
        color={
          (currentValue * 100) / totalValue > 100 ? 'error' : progressBarColor
        }
      />
    </Stack>
  );
}
