import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from '@mui/material';
import type {
  FileWithStatus,
  FileWithSuccessState,
} from 'components/trips/attachments/types';
import { useDebounce } from 'hooks';
import { useEffect, useState } from 'react';
import {
  convertBytesToHumanFileSize,
  getExtensionFromFilename,
  getFileIconFromFileType,
  removeExtensionFromFilename,
} from 'utils/client/formatting';
import { sanitizeFilename } from 'utils/server/sanitizeFilename';

type UploadFilesFileItemProps = {
  file: FileWithStatus;
  onDelete: (fileId: string) => void;
  onUpdateName: (fileId: string, name: string) => void;
};
const UploadFilesFileItem = ({
  file,
  onDelete,
  onUpdateName,
}: UploadFilesFileItemProps) => {
  const [name, setName] = useState(removeExtensionFromFilename(file.file.name));
  const isEmpty = name === '';

  const extension = getExtensionFromFilename(file.file.name);
  const FileIcon = getFileIconFromFileType(file.file.type);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setName(sanitizeFilename(event.target.value));

  const debouncedName = useDebounce(name, 600);

  useEffect(() => {
    if (file.file.name === debouncedName + extension) return;

    const parsedName = sanitizeFilename(
      debouncedName.at(-1) === '.' ? debouncedName.slice(0, -1) : debouncedName,
    ).trim();

    onUpdateName((file as FileWithSuccessState).id, `${parsedName}`);
  }, [debouncedName]);

  return (
    <Card
      elevation={0}
      sx={(theme) => ({ border: `1px solid ${theme.palette.divider}` })}
    >
      <CardHeader
        title={
          <TextField
            variant="filled"
            placeholder="Name"
            value={name}
            onChange={handleNameChange}
            fullWidth
            error={isEmpty}
            helperText={isEmpty ? 'Name cannot be empty' : ''}
            disabled={file.status !== 'success'}
          />
        }
      />
      <CardContent style={{ padding: 0 }}>
        <Box p={2} pt={0}>
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => onDelete((file as FileWithSuccessState).id)}
                disabled={file.status !== 'success'}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            <ListItemAvatar color="red">
              <Avatar sx={{ bgcolor: 'accent.purple.light' }}>
                <FileIcon color="primary" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={name.trim() + extension}
              secondary={`${convertBytesToHumanFileSize(file.file.size)} - ${
                file.status
              }`}
            />
          </ListItem>
          {file.status === 'loading' && (
            <Box width="100%" px={2}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default UploadFilesFileItem;
